import React from "react";
import { IPackageProps } from "../../../utils/interface";
import { DetailIcon } from "../../common/svgIcons";
interface ITableArrayItem {
  name: string;
}

interface IProps {
  data: any;
  tableArray: ITableArrayItem[];
  handleToggleStatus: (id: string) => void;
}

const PackageTable = ({ data, handleToggleStatus, tableArray }: IProps) => {
  if (!data || data?.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="not-found-blk">
            <h1>No package found !</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tab-content">
      <div className="row">
        <div className="col-12">
          <div
            className="table-blk table-responsive"
            style={{ maxHeight: "700px" }}
          >
            <table className="table">
              <thead>
                <tr>
                  {tableArray?.map((item: any, index: number) => (
                    <th key={index}>{item?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((item: IPackageProps, index: number) => {
                  return (
                    <tr key={index}>
                      <td style={{ padding: "28px 32px" }}>{item?.name}</td>
                      <td>${item?.priceDetails?.unitAmount}</td>
                      <td>{item?.description}</td>
                      <td>{item?.priceDetails?.interval}</td>
                      <td>{item?.subscriptionCount}</td>
                      <td>
                        <span
                          className={`${
                            item?.active
                              ? "status-text-success"
                              : "status-text-danger"
                          }`}
                        >
                          {item?.active ? "Active" : "Deactive"}
                        </span>
                      </td>
                      <td>
                        <span className="td-btn-box justify-content-center">
                          <button className="table-trash">
                            <DetailIcon />
                          </button>
                          <span className="switch">
                            <input
                              type="checkbox"
                              checked={item?.active ? true : false}
                            />
                            <span
                              onClick={() => handleToggleStatus(item?.id)}
                              className="slider round"
                            />
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageTable;
