/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ICompanyProps } from "../../../../utils/interface";
import CustomTable from "../../../common/customTable";
import { companyVerifyTableArray } from "../../../../utils/seeds";
import { DeleteIcon, DetailIcon, EditIcon } from "../../../common/svgIcons";
import { useNavigate } from "react-router-dom";
import RoutePath from "../../../../routes/rouePath";
import CustomModal from "../../../common/modal";
import { INITIALVALUES, VALIDATIONS } from "../../../../utils/validation";
import { useFormik } from "formik";
import IMAGES from "../../../../utils/constants/images";
import usePut from "../../../../react-query/hooks/usePut";
import ENDPOINTS from "../../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../../react-query/services/queryKeys";
import { toast } from "react-custom-alert";
import PhoneInput from "react-phone-input-2";

interface IProps {
  data: any;
  handleToggleStatus: (items: ICompanyProps) => void;
  handleDeleteCompany: (id: string) => void;
  companyListRefetch: any;
}

const VerifyCompaniesList = ({
  data,
  handleToggleStatus,
  handleDeleteCompany,
  companyListRefetch,
}: IProps) => {
  const nav = useNavigate();
  const fileInputRef = React.useRef<any>(null);

  // State to manage the pagination
  const [pageNumber, setPageNumber] = React.useState(1);
  const [companyData, setCompanyData] = React.useState<ICompanyProps>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [companyLogo, setCompanyLogo] = React.useState<any>(null);

  // mutateAsync for edit user
  const {
    mutateAsync: editCompanyMutate,
    isLoading,
    isSuccess,
  } = usePut(ENDPOINTS.EDIT_COMPANY_DETAIL, QUERY_KEYS.EDIT_COMPANY_QUERY_KEY);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: INITIALVALUES.editCompanyInitialValues,
    validationSchema: VALIDATIONS.editCompanyValidationSchema,
    onSubmit: async () => {
      try {
        const formData = new FormData();
        formData.append("companyId", companyData?.profileId || "");
        formData.append("name", values.name);
        formData.append("profile_address", values.address);
        formData.append("email", values.email);
        formData.append("website", values.website);
        formData.append("phoneNumber", values.phoneNumber);
        formData.append("countryCode", values.countryCode);
        formData.append("profileImage", companyLogo || values?.profileImage);
        await editCompanyMutate(formData);
      } catch (error) {
        console.error("Error updating user:", error);
      }
    },
  });

  // Update page number for pagination
  function handlePageChange(newPage: number) {
    setPageNumber(newPage);
  }

  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    setCompanyLogo(file);
    setFieldValue("profileImage", file);
  }

  function handleSelectFromLibrary() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  function renderVerifiedCompanyList() {
    return data?.requests?.map((item: ICompanyProps, index: number) => {
      return (
        <tr key={index}>
          <td>
            <span className="td-user-img">
              <img
                onClick={() => window.open(item?.profileImage)}
                src={item?.profileImage}
                alt="User"
              />
            </span>
          </td>
          <td>{item?.name}</td>
          <td>{item?.email}</td>
          <td>{item?.profile_address}</td>
          <td>{`${item?.countryCode} ${item?.phoneNumber}`}</td>
          <td>
            <span
              className={`text-capitalize text fw-bold ${
                item?.status === "verified" ? "text-success" : "text-danger"
              }`}
            >
              {item?.status}
            </span>
          </td>
          <td>
            <div className="icons-main">
              <button
                onClick={() =>
                  nav(RoutePath.COMPANYDETAIL.path, { state: item?.companyId })
                }
                className="table-trash"
              >
                <DetailIcon />
              </button>
              <button
                onClick={() => {
                  setCompanyData(item);
                  setIsModalOpen(true);
                }}
                className="table-trash"
              >
                <EditIcon />
              </button>
              <button
                onClick={() => handleDeleteCompany(item?.companyId)}
                className="table-trash"
              >
                <DeleteIcon />
              </button>
              <span className="switch">
                <input
                  type="checkbox"
                  checked={item?.status === "verified" ? true : false}
                />
                <span
                  onClick={() => handleToggleStatus(item)}
                  className="slider round"
                />
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }

  const renderEditCompany = () => {
    return (
      <div>
        <div className="profile-img-main">
          <div className="d-flex">
            {companyLogo ? (
              <img
                className="profile-img"
                src={URL.createObjectURL(companyLogo)}
                alt="profileImg"
              />
            ) : (
              <img
                className="profile-img"
                src={values.profileImage || IMAGES.profileLogo}
                alt="profileImg"
              />
            )}
            <input
              onChange={(e: any) => handleImageChange(e)}
              type="file"
              accept="image/*"
              className="d-none"
              ref={fileInputRef}
            />
            <label
              onClick={() => handleSelectFromLibrary()}
              className="flex-column d-flex justify-content-center align-items-center"
            >
              Upload Image
            </label>
          </div>
          {errors.profileImage && (
            <p className="error-text">{errors.profileImage}</p>
          )}
        </div>
        <div className="row gy-3">
          <div className="col-sm-6">
            <input
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              className="form-control common-input"
            />
            {errors.name && touched.name && (
              <p className="error-text">{errors.name}</p>
            )}
          </div>
          <div className="col-sm-6">
            <input
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control common-input"
            />
            {errors.email && touched.email && (
              <p className="error-text">{errors.email}</p>
            )}
          </div>
          <div className="col-sm-6">
            <PhoneInput
              containerClass=" form-control common-input"
              placeholder="Mobile Number "
              country={"in"}
              value={`${values.countryCode}${values.phoneNumber}`}
              onChange={(value, data: any) => {
                const rawPhone = value
                  .replace(/[^0-9]+/g, "")
                  .slice(data?.dialCode?.length);
                setFieldValue("phoneNumber", rawPhone);
                setFieldValue("countryCode", data.dialCode);
              }}
            />
            {touched.phoneNumber && errors.phoneNumber && (
              <p className="error-text">{errors.phoneNumber}</p>
            )}
          </div>
          <div className="col-sm-6">
            <input
              name="address"
              value={values?.address}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control common-input"
            />
            {errors.address && touched.address && (
              <p className="error-text">{errors.address}</p>
            )}
          </div>
          <div className="col-sm-6">
            <input
              name="website"
              value={values?.website}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control common-input"
            />
            {errors.website && touched.website && (
              <p className="error-text">{errors.website}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCompanyData(undefined);
    setCompanyLogo(null);
  };
  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Company detail updated successfully");
      resetForm();
      setIsModalOpen(false);
      setCompanyLogo(null);
      companyListRefetch();
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (companyData) {
      resetForm({
        values: {
          countryCode: companyData?.countryCode || "",
          email: companyData?.email || "",
          name: companyData?.name || "",
          phoneNumber: companyData?.phoneNumber || "",
          profileImage: companyData?.profileImage || "",
          address: companyData?.profile_address || "",
          website: companyData?.website || "",
        },
      });
    }
  }, [companyData]);

  React.useEffect(() => {
    companyListRefetch();
  }, [pageNumber]);

  return (
    <CustomTable
      notFoundMessage="No verified company found !"
      data={data?.requests}
      tableArray={companyVerifyTableArray}
      count={5}
      totalRecords={data?.totalRequests}
      pageNumber={pageNumber}
      onPageChange={handlePageChange}
    >
      {renderVerifiedCompanyList()}
      <CustomModal
        btnTitle="Save"
        closeModalFn={handleCloseModal}
        loading={isLoading}
        open={isModalOpen}
        saveFn={handleSubmit}
        title="Edit Company Detail"
      >
        {renderEditCompany()}
      </CustomModal>
    </CustomTable>
  );
};

export default VerifyCompaniesList;
