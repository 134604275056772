import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./context/authContext";
import RouteStack from "./routes/routeStack";
import "react-custom-alert/dist/index.css";
import { ToastContainer } from "react-custom-alert";
import { SideBarProvider } from "./context/sidebarContext";
import { ActiveTabProvider } from "./context/activeTabContext";
import "react-phone-input-2/lib/bootstrap.css";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SideBarProvider>
          <ActiveTabProvider>
            <ToastContainer floatingTime={1500} />
            <RouteStack />
          </ActiveTabProvider>
        </SideBarProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
