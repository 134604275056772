import * as Yup from "yup";

const editCompanyInitialValues = {
  name: "",
  address: "",
  email: "",
  website: "",
  countryCode: "",
  phoneNumber: "",
  profileImage: "",
};

const editCompanyValidationSchema = Yup.object().shape({
  name: Yup.string().required("Company name is required*"),
  address: Yup.string().required("Address is required*"),
  email: Yup.string().email().required("Email is required*"),
  website: Yup.string()
    .required("Website link is required*")
    .test("is-valid-website", "Enter valid url*", (value) => {
      const simpleUrlPattern = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
      const httpsPattern = /^https:\/\/.+$/;
      const wwwPattern = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
      return (
        simpleUrlPattern.test(value) ||
        httpsPattern.test(value) ||
        wwwPattern.test(value)
      );
    }),
  phoneNumber: Yup.string().required("Mobile number is required*"),
  countryCode: Yup.string().required("Country Code is required*"),
  profileImage: Yup.string().required("Profile logo is required*"),
});

export { editCompanyInitialValues, editCompanyValidationSchema };
