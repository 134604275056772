/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import ENDPOINTS from "../../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../../react-query/services/queryKeys";
import useGet from "../../../../react-query/hooks/useGet";
import useDelete from "../../../../react-query/hooks/useDelete";
import usePost from "../../../../react-query/hooks/usePost";
import { toast } from "react-custom-alert";
import CustomAlert from "../../../../components/common/alert";
import { IUserProps } from "../../../../utils/interface";
import IMAGES from "../../../../utils/constants/images";
import {
  CrossIcon,
  DeleteIcon,
  DetailIcon,
  EditIcon,
  SuccessIcon,
} from "../../../../components/common/svgIcons";
import SideBarWrapper from "../../../../components/common/sidebarWrapper";
import CustomTable from "../../../../components/common/customTable";
import { userListTableArray } from "../../../../utils/seeds";
import { useNavigate } from "react-router-dom";
import RoutePath from "../../../../routes/rouePath";
import { PuffLoader } from "react-spinners";
import CustomModal from "../../../../components/common/modal";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../../utils/validation";
import usePut from "../../../../react-query/hooks/usePut";

const UsersManagement = () => {
  // State to manage the pagination
  const [pageNumber, setPageNumber] = React.useState(1);

  // Navigation hook for redirecting to other pages
  let nav = useNavigate();
  const fileInputRef = React.useRef<any>(null);

  // Number of records per page
  const [count, setCount] = React.useState(5);
  const [data, setData] = React.useState<IUserProps>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [profileImage, setProfileImage] = React.useState<any>(null);

  // Fetches the list of users and allows refetching the data
  const {
    data: userListData,
    refetch: userListRefetch,
    isFetching,
    refetch,
  } = useGet(ENDPOINTS.GET_USERS_LIST, QUERY_KEYS.GET_USER_LIST_QUERY_KEY, {
    page: pageNumber,
    limit: count,
  });

  // mutateAsync for user deletion
  const { mutateAsync } = useDelete(
    ENDPOINTS.DELETE_USER,
    QUERY_KEYS.DELETE_USER_QUERY_KEY
  );

  // mutateAsync for edit user
  const {
    mutateAsync: editUserMutate,
    isLoading,
    isSuccess,
  } = usePut(
    ENDPOINTS.EDIT_USER,
    QUERY_KEYS.EDIT_USER_QUERY_KEY,
    `${data?._id}`
  );

  // Updates user status (blocked/unblocked)
  const { mutateAsync: changeUserStatus } = usePost(
    ENDPOINTS.UPDATE_USER_STATUS,
    QUERY_KEYS.UPDATE_USER_STATUS_QUERY_KEY
  );
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: INITIALVALUES.userInitialValues,
    validationSchema: VALIDATIONS.userValidationSchema,
    onSubmit: async () => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("profileImage", profileImage || values.profileImage);
        await editUserMutate(formData);
      } catch (error) {
        console.error("Error updating user:", error);
      }
    },
  });

  // Update page number for pagination
  function handlePageChange(newPage: number) {
    setPageNumber(newPage);
  }

  // Toggle verify/unVerify for product status change
  async function handleToggleStatus(id: string) {
    try {
      await changeUserStatus({
        id: id,
      }).then((res) => {
        toast.success(res?.message);
        userListRefetch();
      });
    } catch (error: any) {
      if (error?.response?.status === 404) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  // delete user
  async function handleDeleteUser(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the user?",
      confirmButtonText: "Delete",
      icon: "question",
      onConfirm: async () => {
        try {
          await mutateAsync({
            id: id,
          }).then((res) => {
            toast.success(res?.message);
            userListRefetch();
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    setProfileImage(file);
    setFieldValue("profileImage", file);
  }

  function handleSelectFromLibrary() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  // Memoize the rendered user list to optimize re-rendering
  const renderUsers = () => {
    return userListData?.users?.map((item: IUserProps, index: number) => {
      return (
        <tr key={index}>
          <td>
            <span className="td-user-img">
              {item?.profileImage && item?.profileImage ? (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(item?.profileImage)}
                  src={item?.profileImage}
                  alt="User"
                />
              ) : (
                <img src={IMAGES.profileLogo} alt="User" />
              )}
            </span>
          </td>
          <td>{item?.name}</td>
          <td>{item?.email}</td>
          <td>
            {item?.countryCode && item?.countryCode
              ? `${item?.countryCode} ${item?.phoneNumber}`
              : "N/A"}
          </td>
          <td>
            {item?.isEmailVerified ? <SuccessIcon /> : <CrossIcon />} /
            {item?.isNumberVerified ? <SuccessIcon /> : <CrossIcon />}
          </td>

          <td>
            <span
              className={`${
                item?.isBlocked ? "status-text-danger" : "status-text-success"
              }`}
            >
              {item?.isBlocked ? "Blocked" : "Unblock"}
            </span>
          </td>
          <td>
            <span className="td-btn-box">
              <button
                onClick={() =>
                  nav(RoutePath.USERDETAIL.path, { state: item?._id })
                }
                className="table-trash"
              >
                <DetailIcon />
              </button>
              <button
                onClick={() => {
                  setIsModalOpen(true);
                  setData(item);
                }}
                className="table-trash"
              >
                <EditIcon />
              </button>
              <button
                onClick={() => handleDeleteUser(item?._id)}
                className="table-trash"
              >
                <DeleteIcon />
              </button>
              <span className="switch">
                <input
                  type="checkbox"
                  checked={item?.isBlocked ? true : false}
                />
                <span
                  onClick={() => handleToggleStatus(item?._id)}
                  className="slider round"
                />
              </span>
            </span>
          </td>
        </tr>
      );
    });
  };

  const renderEditUserForm = () => {
    return (
      <div>
        <div className="profile-img-main">
          <div className="d-flex">
            {profileImage ? (
              <img
                className="profile-img"
                src={URL.createObjectURL(profileImage)}
                alt="profileImg"
              />
            ) : (
              <img
                className="profile-img"
                src={values.profileImage || IMAGES.profileLogo}
                alt="profileImg"
              />
            )}
            <input
              onChange={(e: any) => handleImageChange(e)}
              type="file"
              accept="image/*"
              className="d-none"
              ref={fileInputRef}
            />
            <label
              onClick={() => handleSelectFromLibrary()}
              className="flex-column d-flex justify-content-center align-items-center"
            >
              Upload Image
            </label>
          </div>
          {errors.profileImage && (
            <p className="error-text">{errors.profileImage}</p>
          )}
        </div>
        <div className="row gy-3">
          <div className="col-sm-6">
            <input
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              className="form-control common-input"
            />
            {errors.name && touched.name && (
              <p className="error-text">{errors.name}</p>
            )}
          </div>
          <div className="col-sm-6">
            <input
              value={values.email}
              readOnly
              className="form-control common-input"
            />
          </div>
          <div className="col-sm-6">
            <input
              value={values?.countryCode + values.phoneNumber}
              readOnly
              className="form-control common-input"
            />
          </div>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setData(undefined);
    setProfileImage(null);
  };

  useEffect(() => {
    if (data) {
      resetForm({
        values: {
          countryCode: data?.countryCode || "",
          email: data?.email || "",
          name: data?.name || "",
          phoneNumber: data?.phoneNumber || "",
          profileImage: data?.profileImage || "",
        },
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("User updated successfully");
      resetForm();
      setIsModalOpen(false);
      setProfileImage(null);
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    userListRefetch();
  }, [count, pageNumber]);

  return (
    <SideBarWrapper
      description="Manage the list of users in this section!"
      title="Users Management"
    >
      <div className="row mt-26 g-4">
        <div className="col-12">
          {isFetching ? (
            <div className="d-flex hv-70 align-items-center justify-content-center">
              <PuffLoader color="#0ad5e7" size={70} />
            </div>
          ) : (
            <CustomTable
              notFoundMessage="No user found !"
              data={userListData?.users}
              tableArray={userListTableArray}
              count={count}
              totalRecords={userListData?.totalRecord}
              pageNumber={pageNumber}
              onPageChange={handlePageChange}
            >
              {renderUsers()}
            </CustomTable>
          )}
        </div>
        <CustomModal
          open={isModalOpen}
          saveFn={() => handleSubmit()}
          title="Edit User Detail"
          btnTitle="Save"
          closeModalFn={handleCloseModal}
          loading={isLoading}
        >
          {renderEditUserForm()}
        </CustomModal>
      </div>
    </SideBarWrapper>
  );
};

export default UsersManagement;
