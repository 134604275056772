import React, { useState } from "react";
import { toast } from "react-custom-alert";
import Swal from "sweetalert2";
import CustomAlert from "../../../common/alert";
import usePost from "../../../../react-query/hooks/usePost";
import ENDPOINTS from "../../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../../react-query/services/queryKeys";
import SideBarWrapper from "../../../common/sidebarWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import useGet from "../../../../react-query/hooks/useGet";
import { IRequestProps } from "../../../../utils/interface";
import RoutePath from "../../../../routes/rouePath";
import { useActiveTab } from "../../../../context/activeTabContext";
import IMAGES from "../../../../utils/constants/images";
import DetailInfo from "../../../common/detailInfo";
import { PuffLoader } from "react-spinners";
import CustomModal from "../../../common/modal";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../../utils/validation";
import DocumentListTable from "../../../common/documentListTable";

const RequestDetail = () => {
  const location = useLocation();
  let requestedId = location?.state;

  const nav = useNavigate();

  const { setActiveTab } = useActiveTab();
  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: requestActionMutateAsync } = usePost(
    ENDPOINTS.UPDATE_PROFILE_ACTIONS,
    QUERY_KEYS.UPDATE_PROFILE_STATUS_QUERY_KEY
  );
  const { mutateAsync: sendMessageActionMutateAsync } = usePost(
    ENDPOINTS.SEND_MESSAGE_ACTIONS,
    QUERY_KEYS.SEND_MESSAGE_QUERY_KEY
  );
  const { data, isFetching } = useGet(
    ENDPOINTS.GET_REQUEST_DETAIL,
    QUERY_KEYS.GET_REQUEST_DETAIL_LIST_QUERY_KEY,
    {
      requestId: requestedId,
    }
  );
  let getRequestListData: IRequestProps = data?.request;

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: INITIALVALUES.sendMessageInitialValues,
    validationSchema: VALIDATIONS.sendMessageValidationSchema,
    onSubmit: async () => {
      await sendMessageActionMutateAsync({
        companyId: getRequestListData?.companyId,
        message: values.message,
      }).then((res) => {
        toast.success(res?.message);
        resetForm();
        setIsOpen(false);
      });
    },
  });

  // Handle accept request action with confirmation
  async function handleRequestAccept(id: string) {
    CustomAlert({
      title: " Accept the request?",
      confirmButtonText: "Accept",
      icon: "question",
      onConfirm: async () => {
        try {
          requestActionMutateAsync({
            requestId: id,
            status: "verified",
          })
            .then((res) => {
              toast.success(res?.message);
              nav(RoutePath.COMPANYMANAGEMENT.path);
              setActiveTab(1);
            })
            .catch((err) =>
              Swal.fire({
                title: "Error",
                text: err?.response?.data?.message,
                icon: "error",
                timer: 1500,
                showConfirmButton: false,
              })
            );
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // Handle reject request action with confirmation
  async function handleRequestReject(id: string) {
    CustomAlert({
      title: " Reject the request?",
      confirmButtonText: "Reject",
      icon: "question",
      onConfirm: async () => {
        try {
          requestActionMutateAsync({
            requestId: id,
            status: "rejected",
          })
            .then((res) => {
              toast.success(res?.message);
              nav(RoutePath.COMPANYMANAGEMENT.path);
              setActiveTab(0);
            })
            .catch((err) =>
              Swal.fire({
                title: "Error",
                text: err?.response?.data?.message,
                icon: "error",
                timer: 1500,
                showConfirmButton: false,
              })
            );
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  const renderSendMessage = () => {
    return (
      <div className="row">
        <div className="col-lg-10">
          <input
            value={values.message}
            name="message"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            className="form-control common-input"
            placeholder="Send Message"
          />
          {errors.message && touched.message && (
            <p className="error-text">{errors.message}</p>
          )}
        </div>
      </div>
    );
  };
  return (
    <SideBarWrapper
      title="Request Detail"
      description="Show the detail of the request in this section!"
    >
      <div className="container">
        {isFetching ? (
          <div className="d-flex hv-70 align-items-center justify-content-center">
            <PuffLoader color="#0ad5e7" size={70} />
          </div>
        ) : (
          <div className="row mt-26 g-4">
            <div className="d-flex justify-content-end gap-2">
              <button
                onClick={() => handleRequestAccept(getRequestListData?._id)}
                className="btn btn-success"
              >
                Accept
              </button>
              <button
                onClick={() => handleRequestReject(getRequestListData?._id)}
                className="btn btn-danger"
              >
                Reject
              </button>
            </div>
            <div className="col-12 shadow rounded-4">
              <h2 className="info-text mt-3 mb-2">Request Info :</h2>
              <div className="user_detail_wrapper p-3 d-flex justify-content-start gap-4 flex-wrap">
                <div className="user_img_blk">
                  {!getRequestListData?.profileImage ? (
                    <img src={IMAGES.profileLogo} alt="" />
                  ) : (
                    <img src={getRequestListData?.profileImage} alt="" />
                  )}
                </div>
                <div className="user_detail">
                  <div className="row row-cols-1 row-cols-md-1 row-cols-xl-3 g-3">
                    <DetailInfo
                      title="Company Name:"
                      info={getRequestListData?.name}
                    />
                    <DetailInfo
                      title="Registered Address:"
                      info={getRequestListData?.profile_address}
                    />
                    <DetailInfo
                      title="Email Address:"
                      info={getRequestListData?.email}
                    />
                    <DetailInfo
                      title="Phone No (Official):"
                      info={`${getRequestListData?.countryCode} ${getRequestListData?.phoneNumber}`}
                    />
                    <DetailInfo
                      title="Phone No (Personal):"
                      info={`${getRequestListData?.countryCode} ${getRequestListData?.phoneNumber}`}
                    />
                    <DetailInfo
                      title="Website link:"
                      link={getRequestListData?.website}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 shadow rounded-4">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <h2 className="info-text mt-4 mb-2">Documents :</h2>
                <button
                  onClick={() => setIsOpen(true)}
                  className="btn btn-success"
                >
                  Ask for modification
                </button>
              </div>
              <div className="col-12 px-3 py-3">
                <DocumentListTable
                  handleDocDelete={() => {}}
                  data={getRequestListData?.documents}
                  isDocsDelete={false}
                />
              </div>
            </div>
          </div>
        )}
        <CustomModal
          title="Send message for document modification"
          btnTitle="Send"
          closeModalFn={() => {
            setIsOpen(false);
            resetForm();
          }}
          loading={false}
          open={isOpen}
          saveFn={() => handleSubmit()}
        >
          {renderSendMessage()}
        </CustomModal>
      </div>
    </SideBarWrapper>
  );
};

export default RequestDetail;
