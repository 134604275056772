import { useMutation } from "react-query";
import { axiosInstance } from "../services/axiosTokenInstance";

const usePut = (url: string, queryKey: string, id?: string) => {
  const { isLoading, error, mutateAsync, isSuccess } = useMutation(
    queryKey,
    async (data: FormData) => {
      const result = await axiosInstance({
        method: "put",
        url: id ? `${url}/${id}` : url,
        data,
      });
      return result.data;
    },
    {
      onError: (error) => {
        console.log("Error during PUT request:", error);
      },
    }
  );

  return { isLoading, error, mutateAsync, isSuccess };
};

export default usePut;
