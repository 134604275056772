import { loginInitialValues, loginValidationSchema } from "./authValidation";
import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from "./changePassowordValidation";
import {
  documentInitialValues,
  documentValidationSchema,
} from "./documentValdation";
import {
  editCompanyInitialValues,
  editCompanyValidationSchema,
} from "./editCompanyValidation";
import {
  packageInitialValues,
  packageValidationSchema,
} from "./packageValidation";
import {
  sendMessageInitialValues,
  sendMessageValidationSchema,
} from "./sendMessageValidation";
import { userInitialValues, userValidationSchema } from "./userValidation";

const INITIALVALUES = {
  loginInitialValues: loginInitialValues,
  packageInitialValues: packageInitialValues,
  changePasswordInitialValues: changePasswordInitialValues,
  sendMessageInitialValues: sendMessageInitialValues,
  documentInitialValues: documentInitialValues,
  userInitialValues: userInitialValues,
  editCompanyInitialValues: editCompanyInitialValues,
};

const VALIDATIONS = {
  loginValidationSchema: loginValidationSchema,
  packageValidationSchema: packageValidationSchema,
  changePasswordValidationSchema: changePasswordValidationSchema,
  sendMessageValidationSchema: sendMessageValidationSchema,
  documentValidationSchema: documentValidationSchema,
  userValidationSchema: userValidationSchema,
  editCompanyValidationSchema: editCompanyValidationSchema,
};

export { VALIDATIONS, INITIALVALUES };
