import * as Yup from "yup";

// initialValues
const userInitialValues = {
  name: "",
  email: "",
  countryCode: "",
  phoneNumber: "",
  profileImage: "",
};

// validation schema
const userValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required*"),
  profileImage: Yup.string().required("Profile image is required*"),
});

export { userInitialValues, userValidationSchema };
