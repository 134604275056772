import React from "react";
import SideBarWrapper from "../../../components/common/sidebarWrapper";
import BarGraph from "../../../components/screens/dashboard/barGraph";
import PieChart from "../../../components/screens/dashboard/pieGraph";

const DashboardPage = () => {
  return (
    <SideBarWrapper title="Dashboard" description="Welcome to Admin Dashboard!">
      <div className="row mt-3 gy-4 gy-lg-4">
        <div className="col-lg-6">
          <div className="register-users-graph shadow-2 spacer-24">
            <div className="devices-report-blk p-relative">
              <h2 className="txt-heading-clr">Registered Users Statistics</h2>
            </div>
            <div className="row mt-4">
              <BarGraph />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="register-users-graph shadow-2 spacer-24">
            <div className="devices-report-blk p-relative">
              <h2 className="txt-heading-clr">Business Cards Verified</h2>
            </div>
            <div className="row mt-4">
              <BarGraph />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="register-users-graph shadow-2 spacer-24">
            <div className="devices-report-blk p-relative">
              <h2 className="txt-heading-clr">Subscribed Users Statistics</h2>
            </div>
            <div className="row mt-4 justify-content-center">
              <PieChart />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="register-users-graph shadow-2 spacer-24">
            <div className="devices-report-blk p-relative">
              <h2 className="txt-heading-clr">Revenue Earned Statistics</h2>
            </div>
            <div className="row mt-4 justify-content-center">
              <PieChart />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="register-users-graph shadow-2 spacer-24">
            <div className="devices-report-blk p-relative">
              <h2 className="txt-heading-clr">Verified Company’s Profile</h2>
            </div>
            <div className="row mt-4">
              <BarGraph />
            </div>
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default DashboardPage;
