import { useMutation } from "react-query";
import { axiosInstance } from "../services/axiosTokenInstance";
import userGlobalConfig from "../../utils/constants/globalConfig";

const usePost = (
  url: string,
  queryKey: string,
  isMultipart: boolean = false
) => {
  const { mutate, isLoading, error, mutateAsync, isSuccess } = useMutation(
    queryKey,
    async (data: any) => {
      // Prepare headers based on whether it's multipart or not
      const headers: any = {
        Authorization: `Bearer ${localStorage.getItem(userGlobalConfig.TOKEN)}`, // Pass your token here
      };
      // If the request is multipart (i.e., file upload), set the content-type
      if (isMultipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      const result = await axiosInstance({
        method: "post",
        url: url,
        data: data,
        headers: headers,
      });

      return result.data;
    },
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return { mutate, isLoading, error, mutateAsync, isSuccess };
};

export default usePost;
