const ICON = {
  eyeOpen: "/assets/images/icons/eye-open.svg",
  eyeClose: "/assets/images/icons/eye-close.svg",
  lockIcon: "/assets/images/icons/lock.svg",
  mailIcon: "/assets/images/icons/mail.svg",
  registerUserIcon: "/assets/images/icons/register_user.png",
  businessCardIcon: "/assets/images/icons/business-card.png",
  subscribedUserIcon: "/assets/images/icons/subscribed_user.png",
  revenueEarningUserIcon: "/assets/images/icons/revenue-earning.png",
  verifyCompanyIcon: "/assets/images/icons/verify-company.png",
};

const LOGO = {
  mainLogo: "/assets/images/logo/main-logo.svg",
  profileLogo: "/assets/images/logo/profile.png",
  headerProfileLogo: "/assets/images/logo/user.png",
  leftArrowIcon: "/assets/images/logo/left-table-arrow.svg",
  rightArrowIcon: "/assets/images/logo/right-table-arrow.svg",
  pdfIcon: "/assets/images/logo/pdf.svg",
};

const THEME = {
  mainTheme: "/assets/images/theme/auth.png",
  workOnProgressImg: "/assets/images/theme/work-on-progress.png",
};

const IMAGES = {
  ...ICON,
  ...LOGO,
  ...THEME,
};

export default IMAGES;
