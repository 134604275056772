const ENDPOINTS = {
  // AUTH
  ADMIN_LOGIN: "admin/login",

  //USERS
  GET_USERS_LIST: "admin/usersList",
  GET_USER_DETAIL: "admin/user",
  UPDATE_USER_STATUS: "admin/toggleUserStatus",
  DELETE_USER: "admin/deleteUser",
  EDIT_USER: "admin/editUser",

  // REQUESTS
  GET_PROFILE_REQUESTS: "admin/get-profileRequests",
  GET_REQUEST_DETAIL: "admin/getRequestDetail",
  UPDATE_PROFILE_ACTIONS: "admin/profileActions",
  SEND_MESSAGE_ACTIONS: "admin/send-message",

  // COMPANY
  UPDATE_COMPANY_STATUS: "admin/toggleVerification",
  GET_COMPANY_DETAIL: "admin/getCompanyDetail",
  DELETE_COMPANY: "admin/deleteCompany",
  DELETE_COMPANY_DOCS: "company/deleteDocById",
  UPLOAD_DOCUMENTS: "admin/uploadDocuments",
  EDIT_COMPANY_DETAIL: "admin/editCompanyDetail",

  // PACKAGE
  CREATE_PACKAGE: "admin/create-package",
  GET_PACKAGE_LIST: "admin/get-packagesList",
  UPDATE_PACKAGE_STATUS: "admin/toggle-packageStatus",

  //CHANGE PASSWORD
  CHANGE_PASSWORD: "admin/change-password",
};

export default ENDPOINTS;
