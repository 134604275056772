import * as Yup from "yup";

const documentInitialValues = {
  documentFile: [],
};

const documentValidationSchema = Yup.object({
  documentFile: Yup.array()
    .min(1, "At least one file is required*")
    .of(Yup.mixed().required("A file is required")),
});

export { documentInitialValues, documentValidationSchema };
