import React, { useState } from "react";
import ENDPOINTS from "../../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../../react-query/services/queryKeys";
import SideBarWrapper from "../../../common/sidebarWrapper";
import { useLocation } from "react-router-dom";
import useGet from "../../../../react-query/hooks/useGet";
import { IRequestProps } from "../../../../utils/interface";
import IMAGES from "../../../../utils/constants/images";
import DetailInfo from "../../../common/detailInfo";
import VerifiedCardsTable from "./verifiedCardsTable";
import { PuffLoader } from "react-spinners";
import DocumentListTable from "../../../common/documentListTable";
import useDelete from "../../../../react-query/hooks/useDelete";
import CustomAlert from "../../../common/alert";
import Swal from "sweetalert2";
import { toast } from "react-custom-alert";
import CustomModal from "../../../common/modal";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../../utils/validation";
import usePost from "../../../../react-query/hooks/usePost";

const RequestDetail = () => {
  const location = useLocation();
  let companyId = location?.state;
  const fileInputRef = React.useRef<any>(null);

  const [isOpen, setIsOpen] = useState(false);

  const { data, isFetching, refetch } = useGet(
    ENDPOINTS.GET_COMPANY_DETAIL,
    QUERY_KEYS.GET_COMPANY_DETAIL_LIST_QUERY_KEY,
    {
      companyId: companyId,
    }
  );
  let getCompanyListData: IRequestProps = data?.company;
  const { mutateAsync: deleteCompany, isSuccess: deleteDocSuccess } = useDelete(
    ENDPOINTS.DELETE_COMPANY_DOCS,
    QUERY_KEYS.DELETE_COMPANY_DOCS_QUERY_KEY
  );

  const {
    mutateAsync: uploadDocsMutateAsync,
    isLoading,
    isSuccess,
  } = usePost(
    ENDPOINTS.UPLOAD_DOCUMENTS,
    QUERY_KEYS.UPLOAD_DOCUMENTS_QUERY_KEY,
    true
  );

  const { values, errors, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues: INITIALVALUES.documentInitialValues,
    validationSchema: VALIDATIONS.documentValidationSchema,
    onSubmit: async () => {
      let formData = new FormData();
      formData.append("companyId", getCompanyListData?._id);
      values?.documentFile?.map((docs: any) => formData.append("files", docs));
      try {
        await uploadDocsMutateAsync(formData);
      } catch (error: any) {
        // Handle errors, such as bad requests
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });

  async function handleDocDelete(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the doc?",
      confirmButtonText: "Delete",
      icon: "question",
      onConfirm: async () => {
        try {
          await deleteCompany({
            docId: id,
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // handle file select when upload docs
  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file: any = e.target.files;
    setFieldValue("documentFile", [...file]);
  }

  // handle file select from input ref
  function handleSelectFromLibrary() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  // Remove file by index
  function handleRemoveFile(name: any) {
    const updatedFiles = values?.documentFile.filter(
      (i: any) => i?.name !== name
    );
    setFieldValue("documentFile", updatedFiles);
  }

  const renderUploadDocsModal = () => {
    return (
      <div className="content-upload-blk mt-4">
        <div onClick={handleSelectFromLibrary} className="upload-inner-blk">
          <input
            multiple
            onChange={(e: any) => handleImageChange(e)}
            type="file"
            accept=".pdf,.doc,.docx"
            className="d-none"
            ref={fileInputRef}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 512 512"
            id="upload"
          >
            <path d="M398.1 233.2c0-1.2.2-2.4.2-3.6 0-65-51.8-117.6-115.7-117.6-46.1 0-85.7 27.4-104.3 67-8.1-4.1-17.2-6.5-26.8-6.5-29.5 0-54.1 21.9-58.8 50.5C57.3 235.2 32 269.1 32 309c0 50.2 40.1 91 89.5 91H224v-80h-48.2l80.2-83.7 80.2 83.6H288v80h110.3c45.2 0 81.7-37.5 81.7-83.4 0-45.9-36.7-83.2-81.9-83.3z"></path>
          </svg>
        </div>
        {values?.documentFile?.length > 0 ? (
          <div className="docs-wrapper">
            {values?.documentFile?.map((item: any) => (
              <div className="docs-icon-blk">
                <img width={40} src={IMAGES.pdfIcon} alt="" />
                <button onClick={() => handleRemoveFile(item?.name)}>x</button>
              </div>
            ))}
          </div>
        ) : null}
        {errors.documentFile && (
          <p className="error-text p-0">{errors.documentFile}</p>
        )}
      </div>
    );
  };

  // Show success toast when a doc is successfully deleted
  React.useEffect(() => {
    if (deleteDocSuccess === true) {
      toast.success("Document deleted successfully");
      refetch();
    }
  }, [deleteDocSuccess]);

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Upload document successfully");
      resetForm();
      setIsOpen(false);
      refetch();
    }
  }, [isSuccess]);

  return (
    <SideBarWrapper
      title="Company Detail"
      description="Show the detail of the company in this section!"
    >
      <div className="container">
        {isFetching ? (
          <div className="d-flex hv-70 align-items-center justify-content-center">
            <PuffLoader color="#0ad5e7" size={70} />
          </div>
        ) : (
          <div className="row mt-26 g-4">
            <div className="col-12 shadow rounded-4">
              <h2 className="info-text mt-3 mb-2">Company Info :</h2>
              <div className="user_detail_wrapper p-3 d-flex justify-content-start gap-4 flex-wrap">
                <div className="user_img_blk">
                  {!getCompanyListData?.profileImage ? (
                    <img src={IMAGES.profileLogo} alt="" />
                  ) : (
                    <img src={getCompanyListData?.profileImage} alt="" />
                  )}
                </div>
                <div className="user_detail">
                  <div className="row row-cols-1 row-cols-md-1 row-cols-xl-3 g-3">
                    <DetailInfo
                      title="Company Name :"
                      info={getCompanyListData?.name}
                    />
                    <DetailInfo
                      title="Registered Address :"
                      info={getCompanyListData?.profile_address}
                    />
                    <DetailInfo
                      title="Email Address :"
                      info={getCompanyListData?.email}
                    />
                    <DetailInfo
                      title="Phone No (Official) :"
                      info={`${getCompanyListData?.countryCode} ${getCompanyListData?.phoneNumber}`}
                    />
                    <DetailInfo
                      title="Phone No (Personal) :"
                      info={`${getCompanyListData?.countryCode} ${getCompanyListData?.phoneNumber}`}
                    />
                    <DetailInfo
                      title="Website link :"
                      link={getCompanyListData?.website}
                    />
                    <DetailInfo
                      title="Status of Verification :"
                      info={
                        getCompanyListData?.isCompanyProfileVerified
                          ? "Verified"
                          : "Unverified"
                      }
                    />
                    <DetailInfo
                      title="Subscription Plan Name :"
                      info={getCompanyListData?.plan?.planName}
                    />
                    <DetailInfo
                      title="Subscription Plan Amount :"
                      info={`$${getCompanyListData?.plan?.planAmount}`}
                    />
                    <DetailInfo
                      title="Subscription Plan Status :"
                      info={getCompanyListData?.plan?.status}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 shadow rounded-4">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <h2 className="info-text mt-4 mb-2">Documents :</h2>
                <button
                  className="btn btn-success"
                  onClick={() => setIsOpen(true)}
                >
                  Add new document
                </button>
              </div>
              <div className="col-12 px-3 py-3">
                <DocumentListTable
                  isDocsDelete={true}
                  handleDocDelete={handleDocDelete}
                  data={getCompanyListData?.companyDocuments}
                />
              </div>
            </div>
            <div className="col-12 shadow rounded-4">
              <h2 className="info-text mt-4 mb-2">Verified Cards :</h2>
              <div className="col-12 px-3 py-3">
                <VerifiedCardsTable data={getCompanyListData?.buisnessCards} />
              </div>
            </div>
          </div>
        )}
        <CustomModal
          title="Upload document :"
          btnTitle="Save"
          closeModalFn={() => {
            setIsOpen(false);
            resetForm();
          }}
          loading={isLoading}
          open={isOpen}
          saveFn={() => handleSubmit()}
        >
          {renderUploadDocsModal()}
        </CustomModal>
      </div>
    </SideBarWrapper>
  );
};

export default RequestDetail;
